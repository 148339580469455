import { render, staticRenderFns } from "./safety-supervision-inspecion-add.vue?vue&type=template&id=77005480&scoped=true&"
import script from "./safety-supervision-inspecion-add.vue?vue&type=script&lang=js&"
export * from "./safety-supervision-inspecion-add.vue?vue&type=script&lang=js&"
import style0 from "./safety-supervision-inspecion-add.vue?vue&type=style&index=0&id=77005480&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77005480",
  null
  
)

export default component.exports